<template>
  <v-container fluid>

    <v-row v-if="collegeData">
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="5"
        lg="4"
        class="ma-auto"
      >
        <v-card
          class="px-8 pt-6 pb-12"
          elevation="1"
        >
          <v-card-title class="text-center font-weight-black">
            <v-icon class="mr-2">mdi-google-maps</v-icon> {{$t('base.checkin')}}
          </v-card-title>
          <v-card-subtitle
            v-if="collegeData"
            class="mb-1 mt-1 subtitle-2 grey--text text--lighten-1"
          >{{collegeData.name_th}}</v-card-subtitle>

          <v-card-text>
            <template v-if="!checkerDetail">
              <validation-observer
                ref="form"
                v-slot="{ handleSubmit, reset }"
              >
                <form
                  @submit.prevent="handleSubmit(submitNew)"
                  @reset.prevent="reset"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('base.userChekinType')"
                    rules="required"
                  >
                    <v-combobox
                      class="mb-2"
                      v-model="state.contact_type"
                      :items="contactTypes"
                      :error-messages="errors"
                      :label="$t('base.userChekinType')"
                      item-text="title"
                      item-value="value"
                    ></v-combobox>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('base.idCard')"
                    rules="required|max:13"
                    v-if="state.contact_type.value===1"
                  >
                    <v-text-field
                      v-model="state.pin_card_no"
                      :error-messages="errors"
                      :label="$t('base.idCard')"
                      autocomplete="off"
                      class="mb-2"
                      filled
                      rounded
                      prepend-inner-icon="mdi-card-account-details-outline"
                      :hint="$t('base.required')"
                    ></v-text-field>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('app.edrUsername')"
                    rules="required"
                    v-else-if="state.contact_type.value===2"
                  >
                    <v-text-field
                      v-model="state.pin_card_no"
                      :error-messages="errors"
                      :label="$t('app.edrUsername')"
                      autocomplete="off"
                      class="mb-2"
                      filled
                      rounded
                      prepend-inner-icon="mdi-account-outline"
                      :hint="$t('base.required')"
                    ></v-text-field>
                  </validation-provider>

                  <template v-else-if="state.contact_type.value===3">
                    <!-- <validation-provider
                      v-slot="{ errors }"
                      :name="$t('base.idCard')"
                      rules="max:13"
                    >
                      <v-text-field
                        v-model="state.pin_card_no"
                        :error-messages="errors"
                        :label="$t('base.idCard')"
                        autocomplete="off"
                        class="mb-2"
                        filled
                        rounded
                        prepend-inner-icon="mdi-card-account-details-outline"
                        :hint="$t('base.notRequired')"
                      ></v-text-field>
                    </validation-provider> -->

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('base.fullname')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="state.full_name"
                        :error-messages="errors"
                        :label="$t('base.fullname')"
                        autocomplete="off"
                        class="mb-2"
                        filled
                        rounded
                        prepend-inner-icon="mdi-account-outline"
                        :hint="$t('base.required')"
                      ></v-text-field>
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('base.mobileNo')"
                      rules="required|numeric|min:10|max:10"
                    >
                      <v-text-field
                        v-model="state.mobile_no"
                        :error-messages="errors"
                        :label="$t('base.mobileNo')"
                        autocomplete="off"
                        class="mb-2"
                        filled
                        rounded
                        prepend-inner-icon="mdi-cellphone-iphone"
                        :hint="$t('base.required')"
                      ></v-text-field>
                    </validation-provider>
                  </template>

                  <div class="mt-6 d-flex justify-space-between">
                    <v-btn
                      v-if="newUserCanCheckin"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      depressed
                      block
                    >
                      {{ $t("base.checkin") }}
                    </v-btn>
                  </div>
                </form>
              </validation-observer>
            </template>

            <template v-else-if="checkerDetail">
              <div class="d-flex grow flex-wrap">
                <v-avatar
                  size="128"
                  class="mx-auto elevation-16"
                  color="grey"
                >
                  <img
                    :src="checkerDetail.avatar"
                    alt="John"
                  >
                </v-avatar>
              </div>
              <div class="text-center my-5">

                <h3 class="wee-text-head mb-4">{{checkerDetail.title}}</h3>
                <div
                  class="wee-text-body"
                  v-if="checkInResault.message!=null && checkInResault.status!=null"
                >
                  <p>
                    <v-icon class="mr-2 success--text">mdi-check-circle</v-icon>{{checkInResault.message}}
                  </p>
                  <p v-if="checkInResault.datetime">
                    <v-icon class="mr-2 wee-text-muted">mdi-clock-time-nine-outline</v-icon>
                    <span class="text-subtitle-1">{{$moment(checkInResault.datetime).format('dddd, Do MMMM YYYY, H:mm:ss')}}</span>
                  </p>
                </div>

                <p class="wee-text-muted">{{checkerDetail.typeText}}</p>
                <v-divider></v-divider>
                <v-row v-if="checkInResault.dataCurrentUpdate">
                  <v-col cols="12">

                    <v-progress-circular
                      :rotate="360"
                      :size="100"
                      :width="15"
                      :value="checkInResault.dataCurrentUpdate.percentage"
                      :color="checkInResault.dataCurrentUpdate.color"
                    >
                      {{checkInResault.dataCurrentUpdate.percentage}}
                    </v-progress-circular>
                  </v-col>
                  <v-col cols="12">

                    <v-row>
                      <v-col>
                        <p>
                          <v-icon
                            large
                            class="mr-1"
                          >mdi-account-check-outline</v-icon>
                          <span>{{$t('app.countPeopleNow')}}</span>
                        </p>
                        <h1
                          class="text-h4 transition-swing font-weight-black"
                          :style="'color:'+checkInResault.dataCurrentUpdate.color"
                        >
                          {{Number(checkInResault.dataCurrentUpdate.current).toLocaleString('th')}}
                        </h1>
                      </v-col>
                      <v-col>
                        <p>
                          <v-icon
                            large
                            class="mr-1 "
                          >mdi-account-cancel-outline</v-icon>
                          <span>{{$t('app.checkinLmit')}}</span>
                        </p>
                        <h1 class="text-h4 transition-swing font-weight-black error--text">{{Number(checkInResault.dataCurrentUpdate.limit).toLocaleString('th')}}</h1>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>

                <v-row align="center">
                  <v-col
                    class="text-center"
                    cols="12"
                    v-if="!checkerDetail.key"
                  >
                    <div class="my-2">
                      <v-btn
                        class="success"
                        :loading="loading"
                        :disabled="loading"
                        @click="checkinAction"
                        large
                        depressed
                        block
                      >
                        <v-icon class="mr-2">mdi-login</v-icon>
                        {{$t('base.checkin')}}
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    class="text-center"
                    cols="12"
                    v-if="checkerDetail.key"
                  >
                    <div class="my-2">
                      <v-btn
                        class="error"
                        @click="checkoutAction"
                        :loading="loading"
                        :disabled="loading"
                        large
                        block
                      >
                        <v-icon class="mr-2">mdi-logout</v-icon>{{$t('base.checkout')}}
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    class="text-center"
                    cols="12"
                  >
                    <v-btn
                      text
                      @click="clearCheckerCache"
                    >
                      <v-icon class="mr-2">mdi-delete</v-icon>{{$t('app.deleteDataFromDevice')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

            </template>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-else-if="!loading && !collegeData">
      <wee-error :image="true" />
    </template>

    <base-wee-loader v-model="loading" />
    <wee-confirm ref="weeConfirmRef"></wee-confirm>
    <wee-toast ref="weeToastRef"></wee-toast>
    <core-footer
      :bg="'transparent'"
      :show-to-top="true"
      :showlink="false"
    />
  </v-container>
</template>

<script>
import useBase from "@/composition/UseBase";
import { LocalStorage } from "@/plugins/config";
import { isEmpty } from "@/plugins/util";
import {
  onBeforeUnmount,
  onMounted,
  ref,
  reactive,
  computed
} from "@vue/composition-api";
import EdrColleageService from "@/api/EdrColleageService";
import EdrCheckinService from "@/api/EdrCheckinService";
export default {
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    CoreFooter: () => import("@/views/App/components/core/Footer"),
    WeeError: () => import("@/components/WeeError")
  },
  setup(props, { refs, root }) {
    const collegeCode = ref(null);
    const collegeData = ref(null);
    const loading = ref(false);
    const edrColleageService = new EdrColleageService();
    const edrCheckinService = new EdrCheckinService();
    const { toast } = useBase(refs, root);
    const { $route, $i18n } = root;

    const checkInResault = reactive({
      status: null,
      message: null,
      datetime: null,
      dataCurrentUpdate: null
    });

    const state = reactive({
      contact_type: "",
      pin_card_no: "",
      full_name: "",
      mobile_no: "",
      checkin: true
    });
    const contactTypes = ref([
      { title: $i18n.t("app.checkinUserType1"), value: 1 },
      { title: $i18n.t("app.checkinUserType2"), value: 2 },
      { title: $i18n.t("app.checkinUserType3"), value: 3 }
    ]);

    const localStorageDbName = computed(
      () => LocalStorage.CHECK_IN_USER + "_" + collegeCode.value
    );
    const checkerDetail = ref(null);

    onMounted(async () => {
      checkerDetail.value = await getCheckFromLocalStorage();
      await loadColegeData();
    });

    const getCheckFromLocalStorage = () => {
      return new Promise(resove => {
        collegeCode.value = $route.params.collegeCode;
        const item = localStorage.getItem(localStorageDbName.value)
          ? JSON.parse(localStorage.getItem(localStorageDbName.value))
          : null;
        resove(item);
      });
    };

    const loadColegeData = async () => {
      loading.value = true;
      const { response, error } = await edrColleageService.edrColleageByCode(
        collegeCode.value
      );
      loading.value = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(false);
        });
      }
      toast(response.status, response.message, { timeout: 5000 });
      if (response && response.entity) {
        collegeData.value = response.entity;
      }
      return new Promise(resove => {
        resove(true);
      });
    };

    //in case new user
    const newUserCanCheckin = computed(
      () => {
        if (!state.contact_type) {
          return false;
        }
        if (state.contact_type.value === 1 || state.contact_type.value === 2) {
          return !isEmpty(state.pin_card_no);
        } else if (state.contact_type.value === 3) {
          return !isEmpty(state.full_name) && !isEmpty(state.mobile_no);
        }
      }
      // () => state.contact_type && state.pin_card_no
    );
    const submitNew = async () => {
      let postData = {
        pin_card_no: "",
        checkin: true,
        contact_type: "",
        full_name: "",
        mobile_no: ""
      };
      postData.pin_card_no = state.pin_card_no;
      postData.checkin = true;
      if (state.contact_type.value === 1) {
        postData.contact_type = 1;
      } else if (state.contact_type.value === 2) {
        postData.contact_type = 2;
      } else if (state.contact_type.value === 3) {
        postData.contact_type = 3;
        postData.full_name = state.full_name;
        postData.mobile_no = state.mobile_no;
      }

      loading.value = true;
      const {
        response,
        error
      } = await edrCheckinService.contactTrackingCheckIn(
        postData,
        collegeData.value
      );
      loading.value = false;
      toast(response.server_status, response.server_msg, { timeout: 5000 });
      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(false);
        });
      }

      if (response && response.server_status && response.checkerDetail) {
        localStorage.setItem(
          localStorageDbName.value,
          JSON.stringify(response.checkerDetail)
        );
        checkerDetail.value = response.checkerDetail;

        checkInResault.status = true;
        checkInResault.message = response.server_msg;
        checkInResault.datetime = response.created_at;
        checkInResault.dataCurrentUpdate = response.dataCurrentUpdate;
      } else {
        localStorage.removeItem(localStorageDbName.value);
      }
      return new Promise(resove => {
        closeWindow();
        resove(true);
      });
    };

    const checkinAction = async () => {
      const postData = {
        checkin: true,
        contact_type: checkerDetail.value.type,
        rememberRecord: checkerDetail.value.rememberRecord
      };

      loading.value = true;
      const {
        response,
        error
      } = await edrCheckinService.contactTrackingCheckIn(
        postData,
        collegeData.value
      );
      loading.value = false;
      toast(response.server_status, response.server_msg, { timeout: 5000 });

      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(false);
        });
      }

      if (response && response.server_status && response.checkerDetail) {
        updateUserKey(response.checkerDetail.key);
        checkInResault.status = true;
        checkInResault.message = response.server_msg;
        checkInResault.datetime = response.created_at;
        checkInResault.dataCurrentUpdate = response.dataCurrentUpdate;
      }
      return new Promise(resove => {
        closeWindow();
        resove(true);
      });
    };

    const checkoutAction = async () => {
      const postData = {
        checkin: false,
        contact_type: checkerDetail.value.type
      };

      loading.value = true;
      const {
        response,
        error
      } = await edrCheckinService.contactTrackingCheckOut(
        checkerDetail.value.key,
        postData,
        collegeData.value
      );
      loading.value = false;
      toast(response.server_status, response.server_msg, { timeout: 5000 });

      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(false);
        });
      }

      if (response && response.server_status) {
        updateUserKey(null);
        checkInResault.status = true;
        checkInResault.message = response.server_msg;
        checkInResault.datetime = response.created_at;
        checkInResault.dataCurrentUpdate = response.dataCurrentUpdate;
      }
      return new Promise(resove => {
        closeWindow();
        resove(true);
      });
    };
    const updateUserKey = key => {
      checkerDetail.value.key = key;
      localStorage.setItem(
        localStorageDbName.value,
        JSON.stringify(checkerDetail.value)
      );
    };
    const clearCheckerCache = () => {
      localStorage.removeItem(localStorageDbName.value);
      checkerDetail.value = null;
    };
    const closeWindow = () => {
      //close window if success
      setTimeout(function() {
        const collegeSite =
          collegeData.value && collegeData.value.college_site
            ? collegeData.value.college_site
            : "https://www.google.com/";

        window.location.replace(collegeSite);
      }, 1500);
    };
    onBeforeUnmount(() => {});

    return {
      checkerDetail,
      loading,
      collegeData,
      contactTypes,
      state,
      submitNew,
      checkinAction,
      checkoutAction,
      checkInResault,
      clearCheckerCache,
      newUserCanCheckin
    };
  }
};
</script>
